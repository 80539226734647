<template>
  <NuxtLayout>
    <div class="absolute h-screen w-screen">
      <view class="grid lg:grid-cols-1">
        <view class="overlay ">
          <NuxtImg
            src="https://thunder.pub/background.jpg"
            class="h-screen w-full absolute object-cover opacity-40 opacity-50"
            draggable="false"
            oncontextmenu="return false;"
            format="webp"
            alt="Thunder - 中国最大CS2 HvH 服务器提供商 - CS2 HvH Server - Better than l0veL"
            fit="outside"
          />
          <ULandingHero
            :title="$t('index.landing.hero.title')"
            :description="$t('index.landing.hero.description')"
            :links="[
              {
                label: $t('index.landing.hero.links.label'),
                icon: 'i-heroicons-globe-asia-australia',
                size: 'lg',
                to: localePath('/servers'),
              },
            ]"
            class="mt-28 z-10"
          />
          
          <!-- <ULandingHero
            title="中国最大的CS2 HvH 服务器提供商 - CS2 HvH Server - 比l0veL更好"
            description="专注于高质量的CS2 HvH服务器，提供最好的游戏体验，最专业的技术支持，最优质的客户服务。二次元模型，高性能服务器，全球连接，无需vip也可获得全部游戏体验！"
            class="mt-28 z-0 invisible hidden"
          /> -->
        </view>
      </view>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
const toast = useToast();

toast.add({
  title: '欢迎来到Thunder - 中国最大CS2 HvH 服务器提供商 - CS2 HvH Server - Better than l0veL',
  color: 'green',
});
</script>
